.binance-features
	display: flex
	gap: 16px
	position: relative
	margin: 0 0 32px 0
	@media (max-width: 1000px)
		flex-wrap: wrap
		gap: 20px
		margin: 0 0 16px 0
	&__wrapper
		position: relative
		margin-top: 0
		margin-bottom: 242px
		@media (max-width: 1000px)
			margin: 0 auto 74px auto
			padding: 0
		h2
			margin: 0 auto 16px auto
		p 
			margin: 0 auto 24px auto
			text-align: center
			font-size: 20px
			font-weight: 600
			line-height: 1.4
		.background
			position: absolute
			width: 100vw
			left: calc(-1 * (50vw / 3.2))
			top: 10%
			height: 692px
			background-image: url("/assets/Graphics/pattern.svg")
			background-repeat: no-repeat

.weekly-score
	&__top
		display: flex
		align-items: center
		justify-content: space-between
		gap: 8px
		width: 1066px
		margin: 0 auto 72px auto
		@media (max-width: 1130px)
			box-sizing: border-box
			width: calc( 100% - 56px )
		@media (max-width: 800px)
			flex-wrap: wrap
			justify-content: center
			width: 375px
			max-width: 100%
			margin: 0 auto 16px auto
		@media (max-width: 340px)
			margin: 0 auto 8px auto
			
	&__bottom
		position: relative
		width: 726px
		margin: 0 auto
		@media (max-width: 800px)
			display: flex
			justify-content: center
			align-items: flex-start
			flex-wrap: wrap
			gap: 8px
			width: 375px
			max-width: 100%
		&_1
			width: 248px
			margin: 0 0 8px 0
			@media (max-width: 1130px)
				width: 24%
			@media (max-width: 800px)
				order: 1
				width: 162px
				max-width: calc( 50% - 4px )
				margin: 0
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
		&_2
			width: 248px
			@media (max-width: 1130px)
				width: 24%
			@media (max-width: 800px)
				order: 3
				width: 168px
				max-width: calc( 50% - 4px )
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
		&_3
			width: 262px
			margin: 0 0 24px 0
			@media (max-width: 1130px)
				width: 26%
			@media (max-width: 800px)
				order: 4
				width: 162px
				max-width: calc( 50% - 4px )
				margin: 0
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
		&_4
			width: 257px
			@media (max-width: 1130px)
				width: 26%
			@media (max-width: 800px)
				order: 2
				width: 172px
				max-width: calc( 50% - 4px )
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
		&_5
			width: 388px
			@media (max-width: 1060px)
				width: 48%
			@media (max-width: 800px)
				width: 182px
				max-width: calc( 50% - 4px )
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
		&_6
			position: absolute
			right: 0
			top: 32px
			width: 350px
			@media (max-width: 1060px)
				width: 48%
			@media (max-width: 800px)
				position: relative
				right: auto
				top: auto
				width: 174px
				max-width: calc( 50% - 4px )
				margin: 8px 0 0 0
			@media (max-width: 340px)
				width: calc( 100% - 32px )
				max-width: 100%
				margin: 0
		&_7
			position: absolute
			left: 196px
			top: 254px
			width: 397px
			@media (max-width: 1060px)
				width: 55%
			@media (max-width: 800px)
				left: 109px
				top: 119px
				width: 186px
				max-width: calc( 50% - 4px )
			@media (max-width: 340px)
				position: relative
				top: auto
				left: auto
				width: calc( 100% - 32px )
				max-width: 100%