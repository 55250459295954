.ask-block
    transition: 0.2s background
    background: #EBDEDC
    border-radius: 16px
    width: 100%
    cursor: pointer
    overflow: hidden
    transition: 0.2s height, 0.2s background

    &+&
        margin-top: 12px

    &:hover
        background: #E6D7D5

    &.open img
        transform: rotateZ(-180deg)

    &-header
        display: flex
        align-items: center
        justify-content: space-between
        padding: 32px 24px 32px 24px
        gap: 16px

    &-header p
        margin: 0
        font-weight: 500
        font-size: 20px
        line-height: 27px
        color: #2C3034

    &-header img
        transition: 0.35s transform

    &-body p
        margin: 0
        font-weight: 500
        font-size: 16px
        line-height: 22px
        color: #6B6661

    &-body
        padding: 0 24px 32px 24px
        display: none

    ul
        margin: 0
        padding-left: 24px
        font-weight: 600
        font-size: 16px
        line-height: 22px
        color: #6B6661
