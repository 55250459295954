.preheader
    display: flex
    align-items: center
    position: relative
    z-index: 2
    width: 100%
    height: 40px
    background-color: #232629

    &-center
        display: flex
        align-items: center
        width: 1121px
        margin: 0 auto
        @media (max-width: 1150px)
            width: 100%
            padding: 0 24px
        @media (max-width: 620px)
            padding: 0 16px

    &__left
        display: flex
        padding: 0 60px 0 0
        @media (max-width: 900px)
            order: 2
            margin-left: auto
            padding: 0

    &_big-brain
        margin: 0 18px 0 0

    &__right
        display: flex
        align-items: center
        margin-left: auto
        @media (max-width: 900px)
            order: 1
            margin: 0
        @media (max-width: 680px)
            margin: 0 20px 0 0
    .partnership
        margin: 0 40px 0 0
        font-size: 12px
        font-weight: 500
        text-decoration: underline
        color: rgba(243, 235, 234, 0.6)
        @media (max-width: 680px)
            display: none
        &:hover
            text-decoration: none
            color: rgba(243, 235, 234, 1)
.body_margin .header
    @media (max-width: 900px)
        position: absolute
        top: 40px
        z-index: 100000
.header
    width: 100%
    box-sizing: border-box
    position: relative
    transition: 0.2s background
    z-index: 10001

    &.active
        position: sticky
        top: 0
        z-index: 10002
    &-center
        display: flex
        align-items: center
        padding: 0 24px
        height: 72px
        max-width: 1176px
        box-sizing: border-box
        margin: auto
        @media (max-width: 768px)
            max-width: 100%
        @media (max-width: 620px)
            padding: 0 16px

    &.active
        background: #2C3034
        & .header-body.open
            top: 0
            height: 100vh
    &-link
        width: fit-content
        margin: 0 26px
        font-weight: 600
        font-size: 16px
        line-height: 22px
        color: #F3EBEA
        padding: 4px 0
        cursor: pointer
        text-decoration: none
        transition: 0.2s opacity
        &:hover
            opacity: 0.8
        @media (max-width: 1190px)
            margin: 0 16px
        @media (max-width: 1000px)
            margin: 0 12px
        @media (max-width: 900px)
            margin: 0

    &-link.active
        color: rgba(247, 179, 2, 1)
        border-bottom: 2px solid #F7B302

    &-link.active-white
        color: #fff
        border-bottom: 2px solid #fff

    &-right
        margin-left: auto
        align-items: center
        display: flex
        gap: 16px

    &-download
        display: flex
        align-items: center
        justify-content: center
        width: 172px
        cursor: pointer
        font-weight: 600
        font-size: 16px
        line-height: 22px
        color: #2C3034
        background-color: #F3EBEA
        transition: 0.2s background
        text-decoration: none
        border-radius: 16px
        height: 40px
        white-space: nowrap
        &:hover
            color: #5D666F

        @media (max-width: 900px)
            display: none
            width: auto
            background-color: transparent
            width: 100%
            height: auto
            padding: 12px 0
            border-radius: 16px
            border: 1px solid #2C3034
            background-color: #FCE823
            box-shadow: 4px 4px 0px 0px #FFF
            color: #2C3034
            text-align: center
            font-feature-settings: 'calt' off, 'liga' off
            font-size: 16px
            font-weight: 600
            line-height: 1.25

    .social-link
        display: flex
        transition: 0.2s opacity
        @media (max-width: 900px)
            display: none
        &:hover
            opacity: 0.8

    &-links
        display: flex
        align-items: center
        margin-left: 64px
        @media (max-width: 900px)
            display: none

    &-logo
        width: 95px
        z-index: 10

    &-action
        z-index: 1
        cursor: pointer
        background: #2C3034
        border: 1px solid #FD84E3
        box-shadow: 1.5px 1.5px 0px #FD84E3
        border-radius: 8px
        position: relative
        box-sizing: border-box
        width: 32px
        height: 32px
        margin-left: 32px

        display: none
        @media (max-width: 900px)
            display: block
        @media (max-width: 620px)
            margin-left: 24px

        &::before
            content: ""
            display: block
            transition: .2s all
            transform-origin: center
            background: #F3EBEA
            border-radius: 2px
            position: absolute
            width: 20px
            height: 2px
            left: 5px
            top: 11px

        &::after
            content: ""
            display: block
            transition: .2s all
            transform-origin: center
            background: #F3EBEA
            border-radius: 2px
            position: absolute
            width: 20px
            height: 2px
            left: 5px
            top: 17px

    &-action.open
        &::before
            transform: rotate(45deg)
            top: 14.5px
            left: 5px

        &::after
            transform: rotate(-45deg)
            top: 14.5px
            left: 5px

    &-body
        display: none
        box-sizing: border-box
        flex-direction: column
        justify-content: space-between
        background: #2C3034
        width: 100vw
        padding: 16px
        padding-bottom: 32px
        padding-top: 72px

    &-body:focus-within
        @media (max-width: 780px)
            justify-content: flex-end
            .header-form-hr, .header-mobile-links, .header-form a
                display: none

            .header-form
                position: fixed
                bottom: 32px
                right: 16px
                left: 16px
                width: calc(100vw - 32px)

    &-body.open
        position: fixed
        top: 40px
        left: 0
        right: 0
        display: flex
        /* height: calc(var(--vh) * 100% - 40px) */
        /* height: calc(100vh - 40px) */
        bottom: 0
        .header-form
            gap: 0
    &-mobile-links
        display: flex
        flex-direction: column
        gap: 28px
        @media (max-width: 900px)
            gap: 16px

    &-form
        width: 100%
        display: flex
        flex-direction: column
        gap: 16px

        & a, & button
            width: 100%

    &-form-title
        margin: 16px 0 12px 0
        font-weight: 700
        font-size: 16px
        line-height: 22px
        color: #F3EBEA

    &-form-title span
        color: rgba(253, 132, 227, 1)

    &-form-hr
        display: flex
        align-items: center
        justify-content: center
        gap: 16px
        width: 100%
        font-weight: 500
        font-size: 16px
        line-height: 22px
        color: #F3EBEA
        @media (max-width: 900px)
            margin: 12px 0

        &::before
            content: ""
            display: block
            background: rgba(243, 235, 234, 0.6)
            height: 1px
            flex: 1

        &::after
            content: ""
            display: block
            background: rgba(243, 235, 234, 0.6)
            height: 1px
            flex: 1

.scroll-up
    z-index: 10002
    cursor: pointer
    position: fixed
    bottom: -200px
    right: 32px
    width: 48px
    height: 48px
    background: #F3EBEA
    border: 1px solid #2C3034
    border-radius: 16px
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    padding: 12px
    gap: 4px

    transition: 0.2s transform, 0.2s bottom

    &.active
        bottom: 100px
        @media (max-width: 620px)
            bottom: 20px
            right: 100px

    &:hover
        transform: translateY(-4px)

.float-qr
    display: flex
    flex-direction: column
    align-items: center
    padding: 16px
    gap: 16px

    box-sizing: border-box
    position: fixed
    z-index: 10002
    width: 112px
    left: 20px

    transition: 0.2s bottom
    bottom: -200px

    background: #2C3034
    border-radius: 16px

    @media (max-width: 780px)
        display: none

    &.active
        bottom: 20px

    img
        width: 80px
        height: 80px

    p
        margin: 0
        font-weight: 500
        font-size: 14px
        line-height: 19px
        text-align: center
        color: #F3EBEA

.appstore, .appandroid, .apprandom
    display: none
.app-show
    display: inline-block

.header-note
    position: relative
    padding: 10px 0
    background-color: #FD84E3
    @media (max-width: 620px)
        box-sizing: border-box
        position: fixed
        left: 16px
        bottom: 64px
        z-index: 10002
        width: calc(100% - 32px)
        padding: 12px 48px 12px 16px
        border: 1px solid #2C3034
        box-shadow: 4px 4px 0px #2C3034
        border-radius: 12px
        opacity: 0
        transition: all 0.5s
        &.open
            .header-note__close
                display: block
    &__close
        content: ""
        display: none
        position: absolute
        right: 16px
        top: calc( 50% - 10px )
        width: 21px
        height: 21px
        background-image: url(../assets/icons/close-round.svg)
        background-position: 50%
        background-repeat: no-repeat
        background-size: 100% auto
        cursor: pointer
    p
        margin: 0
        font-weight: 600
        font-size: 14px
        line-height: 20px
        text-align: center
        color: #2C3034
        @media (max-width: 620px)
            text-align: left
        a
            color: #2C3034
            text-decoration: underline
            white-space: nowrap
            &:hover
                text-decoration: none
