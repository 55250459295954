@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  background-color: #2c3034;
  margin: 0;
  padding: 0;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: CabinetGrotesk, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
  font-weight: 800;
}

span.dot {
  color: #fd84e3;
  font-family: CabinetGrotesk;
  font-weight: 800;
}

section {
  max-width: 1120px;
  margin: 164px auto 0;
}

@media (max-width: 1160px) {
  section {
    margin-top: 80px;
    padding: 0 24px;
  }
}

@media (max-width: 1000px) {
  section {
    padding: 0 16px;
  }
}

section > h2 {
  text-align: center;
  letter-spacing: .02em;
  color: #2c3034;
  width: 80%;
  margin: auto auto 56px;
  font-size: 56px;
  font-weight: 900;
  line-height: 69px;
  display: block;
}

@media (max-width: 768px) {
  section > h2 {
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 580px) {
  section > h2 {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
  }
}

.here-button.light {
  box-shadow: 4px 4px #fff;
}

.here-button {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  color: #2c3034;
  background: #f7b302;
  border: 1px solid #2c3034;
  border-radius: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 180px;
  height: 48px;
  padding: 12px 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  text-decoration: none;
  transition: background .2s;
  display: flex;
  box-shadow: 4px 4px #2c3034;
}

.here-button:hover {
  background: #ffcc4a;
}

.here-button:disabled {
  cursor: default;
  color: #6b6661;
  background: #cbc6c5;
  border: 1px solid #6b6661;
  box-shadow: 4px 4px #6b6661;
}

.here-button.pink {
  background: #fd84e3;
}

.here-button.pink:hover {
  background: #fd9be8;
}

.here-button.sunyellow {
  background-color: #fce823;
}

.here-button.sunyellow:hover {
  background-color: #fdbf1e;
}

.here-input, .here-input-nick {
  color: #2c3034;
  background: #ebdedc;
  border: 1px solid #2c3034;
  border-radius: 16px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 56px;
  padding: 0 16px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
}

.here-input::placeholder, .here-input-nick::placeholder {
  color: #6b6661;
}

.page {
  position: relative;
}

.page-background {
  z-index: -1;
  background: #f3ebea;
  border-radius: 32px;
  height: 100%;
  display: block;
  position: absolute;
  left: 88px;
  right: 88px;
}

.full-hide {
  display: none;
}

.preheader {
  z-index: 2;
  background-color: #232629;
  align-items: center;
  width: 100%;
  height: 40px;
  display: flex;
  position: relative;
}

.preheader-center {
  align-items: center;
  width: 1121px;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 1150px) {
  .preheader-center {
    width: 100%;
    padding: 0 24px;
  }
}

@media (max-width: 620px) {
  .preheader-center {
    padding: 0 16px;
  }
}

.preheader__left {
  padding: 0 60px 0 0;
  display: flex;
}

@media (max-width: 900px) {
  .preheader__left {
    order: 2;
    margin-left: auto;
    padding: 0;
  }
}

.preheader_big-brain {
  margin: 0 18px 0 0;
}

.preheader__right {
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media (max-width: 900px) {
  .preheader__right {
    order: 1;
    margin: 0;
  }
}

@media (max-width: 680px) {
  .preheader__right {
    margin: 0 20px 0 0;
  }
}

.preheader .partnership {
  color: #f3ebea99;
  margin: 0 40px 0 0;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
}

@media (max-width: 680px) {
  .preheader .partnership {
    display: none;
  }
}

.preheader .partnership:hover {
  color: #f3ebea;
  text-decoration: none;
}

@media (max-width: 900px) {
  .body_margin .header {
    z-index: 100000;
    position: absolute;
    top: 40px;
  }
}

.header {
  box-sizing: border-box;
  z-index: 10001;
  width: 100%;
  transition: background .2s;
  position: relative;
}

.header.active {
  z-index: 10002;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.header-center {
  box-sizing: border-box;
  align-items: center;
  max-width: 1176px;
  height: 72px;
  margin: auto;
  padding: 0 24px;
  display: flex;
}

@media (max-width: 768px) {
  .header-center {
    max-width: 100%;
  }
}

@media (max-width: 620px) {
  .header-center {
    padding: 0 16px;
  }
}

.header.active {
  background: #2c3034;
}

.header.active .header-body.open {
  height: 100vh;
  top: 0;
}

.header-link {
  color: #f3ebea;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 26px;
  padding: 4px 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: opacity .2s;
}

.header-link:hover {
  opacity: .8;
}

@media (max-width: 1190px) {
  .header-link {
    margin: 0 16px;
  }
}

@media (max-width: 1000px) {
  .header-link {
    margin: 0 12px;
  }
}

@media (max-width: 900px) {
  .header-link {
    margin: 0;
  }
}

.header-link.active {
  color: #f7b302;
  border-bottom: 2px solid #f7b302;
}

.header-link.active-white {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.header-right {
  align-items: center;
  gap: 16px;
  margin-left: auto;
  display: flex;
}

.header-download {
  cursor: pointer;
  color: #2c3034;
  white-space: nowrap;
  background-color: #f3ebea;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
  transition: background .2s;
  display: flex;
}

.header-download:hover {
  color: #5d666f;
}

@media (max-width: 900px) {
  .header-download {
    color: #2c3034;
    text-align: center;
    font-feature-settings: "calt" off, "liga" off;
    background-color: #fce823;
    border: 1px solid #2c3034;
    border-radius: 16px;
    width: 100%;
    height: auto;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    display: none;
    box-shadow: 4px 4px #fff;
  }
}

.header .social-link {
  transition: opacity .2s;
  display: flex;
}

@media (max-width: 900px) {
  .header .social-link {
    display: none;
  }
}

.header .social-link:hover {
  opacity: .8;
}

.header-links {
  align-items: center;
  margin-left: 64px;
  display: flex;
}

@media (max-width: 900px) {
  .header-links {
    display: none;
  }
}

.header-logo {
  z-index: 10;
  width: 95px;
}

.header-action {
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
  background: #2c3034;
  border: 1px solid #fd84e3;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  margin-left: 32px;
  display: none;
  position: relative;
  box-shadow: 1.5px 1.5px #fd84e3;
}

@media (max-width: 900px) {
  .header-action {
    display: block;
  }
}

@media (max-width: 620px) {
  .header-action {
    margin-left: 24px;
  }
}

.header-action:before {
  content: "";
  transform-origin: center;
  background: #f3ebea;
  border-radius: 2px;
  width: 20px;
  height: 2px;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 11px;
  left: 5px;
}

.header-action:after {
  content: "";
  transform-origin: center;
  background: #f3ebea;
  border-radius: 2px;
  width: 20px;
  height: 2px;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 17px;
  left: 5px;
}

.header-action.open:before {
  top: 14.5px;
  left: 5px;
  transform: rotate(45deg);
}

.header-action.open:after {
  top: 14.5px;
  left: 5px;
  transform: rotate(-45deg);
}

.header-body {
  box-sizing: border-box;
  background: #2c3034;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  padding: 72px 16px 32px;
  display: none;
}

@media (max-width: 780px) {
  .header-body:focus-within {
    justify-content: flex-end;
  }

  .header-body:focus-within .header-form-hr, .header-body:focus-within .header-mobile-links, .header-body:focus-within .header-form a {
    display: none;
  }

  .header-body:focus-within .header-form {
    width: calc(100vw - 32px);
    position: fixed;
    bottom: 32px;
    left: 16px;
    right: 16px;
  }
}

.header-body.open {
  display: flex;
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}

.header-body.open .header-form {
  gap: 0;
}

.header-mobile-links {
  flex-direction: column;
  gap: 28px;
  display: flex;
}

@media (max-width: 900px) {
  .header-mobile-links {
    gap: 16px;
  }
}

.header-form {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  display: flex;
}

.header-form a, .header-form button {
  width: 100%;
}

.header-form-title {
  color: #f3ebea;
  margin: 16px 0 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.header-form-title span {
  color: #fd84e3;
}

.header-form-hr {
  color: #f3ebea;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
}

@media (max-width: 900px) {
  .header-form-hr {
    margin: 12px 0;
  }
}

.header-form-hr:before, .header-form-hr:after {
  content: "";
  background: #f3ebea99;
  flex: 1;
  height: 1px;
  display: block;
}

.scroll-up {
  z-index: 10002;
  cursor: pointer;
  box-sizing: border-box;
  background: #f3ebea;
  border: 1px solid #2c3034;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 48px;
  height: 48px;
  padding: 12px;
  transition: transform .2s, bottom .2s;
  display: flex;
  position: fixed;
  bottom: -200px;
  right: 32px;
}

.scroll-up.active {
  bottom: 100px;
}

@media (max-width: 620px) {
  .scroll-up.active {
    bottom: 20px;
    right: 100px;
  }
}

.scroll-up:hover {
  transform: translateY(-4px);
}

.float-qr {
  box-sizing: border-box;
  z-index: 10002;
  background: #2c3034;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 112px;
  padding: 16px;
  transition: bottom .2s;
  display: flex;
  position: fixed;
  bottom: -200px;
  left: 20px;
}

@media (max-width: 780px) {
  .float-qr {
    display: none;
  }
}

.float-qr.active {
  bottom: 20px;
}

.float-qr img {
  width: 80px;
  height: 80px;
}

.float-qr p {
  text-align: center;
  color: #f3ebea;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.appstore, .appandroid, .apprandom {
  display: none;
}

.app-show {
  display: inline-block;
}

.header-note {
  background-color: #fd84e3;
  padding: 10px 0;
  position: relative;
}

@media (max-width: 620px) {
  .header-note {
    box-sizing: border-box;
    z-index: 10002;
    opacity: 0;
    border: 1px solid #2c3034;
    border-radius: 12px;
    width: calc(100% - 32px);
    padding: 12px 48px 12px 16px;
    transition: all .5s;
    position: fixed;
    bottom: 64px;
    left: 16px;
    box-shadow: 4px 4px #2c3034;
  }

  .header-note.open .header-note__close {
    display: block;
  }
}

.header-note__close {
  content: "";
  cursor: pointer;
  background-image: url("close-round.410eb514.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 21px;
  height: 21px;
  display: none;
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
}

.header-note p {
  text-align: center;
  color: #2c3034;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@media (max-width: 620px) {
  .header-note p {
    text-align: left;
  }
}

.header-note p a {
  color: #2c3034;
  white-space: nowrap;
  text-decoration: underline;
}

.header-note p a:hover {
  text-decoration: none;
}

body {
  background-image: url("grid-perspective.b228209a.svg");
  background-position: 50% 80px;
  background-repeat: no-repeat;
}

@media (max-width: 900px) {
  body {
    background-position: 50% 40px;
    background-size: 130%;
  }
}

@media (max-width: 620px) {
  body {
    background-size: 200%;
  }
}

.main-mobile {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  padding: 88px 24px 0;
  display: none;
}

.main-mobile h1 {
  text-align: center;
  color: #f3ebea;
  margin: 0;
  font-size: 48px;
  font-weight: 800;
  line-height: 60px;
}

.main-mobile > a {
  margin-top: 32px;
}

.main-mobile > img {
  margin-top: 62px;
  margin-bottom: -20px;
}

@media (max-width: 420px) {
  .main-mobile h1 {
    font-size: 32px;
    line-height: 48px;
  }
}

.main-mobile-near {
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  display: flex;
}

.main-mobile-near p {
  color: #f3ebea;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.main {
  width: 100%;
  margin: 0 0 56px;
  position: relative;
}

@media (max-width: 900px) {
  .main {
    margin: 0 0 72px;
  }
}

@media (max-width: 768px) {
  .main {
    margin: 0 0 64px;
  }
}

.main-center {
  flex-wrap: wrap;
  max-width: 1095px;
  margin: 72px auto 0;
}

@media (max-width: 768px) {
  .main-center {
    max-width: 615px;
    margin-top: 24px;
  }
}

.main-header {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 60px 0 0;
  display: flex;
}

@media (max-width: 1200px) {
  .main-header {
    box-sizing: border-box;
    padding: 92px 24px 0;
  }
}

@media (max-width: 900px) {
  .main-header {
    padding: 64px 0 0;
  }
}

@media (max-width: 620px) {
  .main-header {
    padding: 24px 0 0;
  }
}

.main-header__left {
  padding: 0 0 0 8px;
}

@media (max-width: 900px) {
  .main-header__left {
    display: none;
  }
}

.main-header__left img {
  margin: 0 0 80px;
}

.main-header__center {
  text-align: center;
}

@media (max-width: 900px) {
  .main-header__center {
    margin: 0 auto;
  }
}

.main-header__center h1 {
  text-transform: uppercase;
  color: #0000;
  background: linear-gradient(90.02deg, #fdbe1d 18.72%, #fca857 41.42%, #fc668b 61.68%, #c74ad5 79.52%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: .01em;
  font-feature-settings: "liga" off;
  font-size: 80px;
  font-weight: 900;
  line-height: 1;
}

@media (max-width: 900px) {
  .main-header__center h1 {
    font-size: 64px;
    line-height: 72px;
  }
}

@media (max-width: 620px) {
  .main-header__center h1 {
    font-size: 44px;
    line-height: 48px;
  }
}

.main-header__center h3 {
  text-transform: uppercase;
  color: #0000;
  background: linear-gradient(#f3ebea 55.15%, #f3ebea99 89.29%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  letter-spacing: .01em;
  font-feature-settings: "liga" off;
  margin: 0 0 18px;
  font-size: 80px;
  font-weight: 900;
  line-height: 1;
}

@media (max-width: 900px) {
  .main-header__center h3 {
    font-size: 64px;
    line-height: 72px;
  }
}

@media (max-width: 620px) {
  .main-header__center h3 {
    font-size: 44px;
    line-height: 48px;
  }
}

.main-header__center p {
  letter-spacing: .01em;
  color: #f3ebea99;
  max-width: 572px;
  margin: 0 auto;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 900px) {
  .main-header__center p {
    margin: 0 auto 48px;
  }
}

@media (max-width: 620px) {
  .main-header__center p {
    width: 320px;
    font-size: 14px;
  }
}

.main-header-waitlist {
  display: none;
}

@media (max-width: 900px) {
  .main-header-waitlist {
    display: inline-block;
  }
}

.main-header-waitlist a {
  text-align: center;
  font-feature-settings: "liga" off, "calt" off;
  cursor: pointer;
  color: #2c3034;
  background: #fd84e3;
  border: 1px solid #2c3034;
  border-radius: 16px;
  padding: 13px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  display: block;
  box-shadow: 4px 4px #fff;
}

@media (max-width: 620px) {
  .main-header-waitlist a {
    padding: 13px 20px;
  }
}

@media (max-width: 900px) {
  .main-header__right {
    display: none;
  }
}

.main-header__appclip p {
  color: #f3ebea99;
  margin: 0 0 8px;
  font-size: 14px;
}

.qrcode-main {
  width: 128px;
  margin: 0 0 32px;
  display: block;
}

.interface {
  z-index: 1;
  justify-content: center;
  align-items: flex-end;
  max-width: 1095px;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.interface img {
  width: 308px;
}

@media (max-width: 900px) {
  .interface img {
    width: 218px;
  }
}

@media (max-width: 620px) {
  .interface img {
    width: 160px;
  }
}

.page-minus {
  z-index: 10001;
  margin: -180px auto 0;
  position: relative;
}

@media (max-width: 900px) {
  .page-minus {
    margin: -132px auto 0;
  }
}

@media (max-width: 620px) {
  .page-minus {
    margin: -96px auto 0;
  }
}

.features {
  margin: 0 auto;
  padding: 104px 64px 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .features {
    padding: 48px 20px;
  }
}

.feature {
  align-items: center;
  gap: 16px;
  width: 100%;
  margin: 0 0 164px;
  display: flex;
}

@media (max-width: 1160px) {
  .feature {
    margin: 0 0 80px;
  }
}

@media (max-width: 620px) {
  .feature {
    margin: 0 0 64px;
  }
}

.feature:first-child {
  flex-wrap: wrap;
  margin: 0 0 112px;
}

@media (max-width: 620px) {
  .feature:first-child {
    margin: 0 0 32px;
  }
}

.feature:nth-child(2) .dot {
  display: none;
}

@media (max-width: 620px) {
  .feature {
    flex-direction: column-reverse;
    gap: 40px;
  }
}

.feature .feature-picture-phone, .feature .feature-picture-phone img {
  width: 100%;
}

.feature .feature-picture-phone .no-slider {
  z-index: 2;
}

.feature:nth-child(2n) {
  flex-direction: row-reverse;
}

@media (max-width: 620px) {
  .feature:nth-child(2n) {
    flex-direction: column-reverse;
  }
}

.feature:nth-child(odd) .feature-content {
  padding: 0 0 0 48px;
}

@media (max-width: 620px) {
  .feature:nth-child(odd) .feature-content {
    padding: 0;
  }
}

.feature h2 {
  margin-bottom: 24px;
}

.feature-picture-phone {
  z-index: 100;
  width: 100%;
}

.feature-content {
  width: calc(50% - 56px);
}

@media (max-width: 620px) {
  .feature-content {
    width: 100%;
  }
}

.feature-content h2 {
  letter-spacing: .02em;
  color: #2c3034;
  font-size: 54px;
  font-weight: 900;
  line-height: 69px;
}

@media (max-width: 768px) {
  .feature-content h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  }
}

.feature-content a {
  color: #2c3034;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 36px -12px -4px;
  padding: 4px 24px 4px 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  text-decoration: none;
  transition: background .2s;
  display: flex;
}

.feature-content a:hover {
  background: #ebdedc;
}

.feature-content a:hover img {
  transform: translateX(6px);
}

@media (max-width: 768px) {
  .feature-content a {
    font-size: 16px;
    line-height: 22px;
  }
}

.feature-content a img {
  margin-left: 16px;
  transition: transform .2s;
}

.feature-content ul {
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.feature-content li {
  color: #2c3034;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  display: flex;
}

@media (max-width: 768px) {
  .feature-content li {
    color: #2c3034;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
}

.feature-content li.active:before {
  background: #fd84e3;
}

.feature-content li:before {
  content: "";
  float: left;
  background-color: #ead3e5;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-top: 8px;
  margin-right: 8px;
  display: block;
}

.slider {
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  background-image: url("features-bg.d1eb088a.svg");
  background-position: 50%;
  background-size: 100%;
  justify-content: center;
  align-items: center;
  width: calc(50% - 8px);
  display: flex;
  position: relative;
}

@media (max-width: 620px) {
  .slider {
    width: 100%;
  }
}

.slides {
  z-index: 3;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.slides img {
  opacity: 0;
  width: 100%;
  transition: opacity .8s ease-in, transform .8s cubic-bezier(.4, 1, .55, 1.05);
  transform: translateX(-100%);
}

.slides img.active {
  opacity: 1;
  transform: translateX(0%);
}

.pagination {
  z-index: 3;
  text-align: center;
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translate(-50%);
}

.pagination span {
  cursor: pointer;
  background-color: #d9cdcb;
  border: none;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  display: inline-block;
}

.pagination span.active {
  background-color: #fd84e3;
}

.banner-get-gas {
  box-sizing: border-box;
  background: linear-gradient(92deg, #fdbf1c 0%, #fda31c 100%);
  border-radius: 16px;
  width: 1135px;
  margin: 40px auto 0;
  padding: 32px 44px;
  position: relative;
}

@media (max-width: 1160px) {
  .banner-get-gas {
    width: 100%;
  }
}

@media (max-width: 620px) {
  .banner-get-gas {
    order: -1;
    height: auto;
    margin: 0 auto;
    padding: 18px 26px;
  }
}

.banner-get-gas:after {
  content: "";
  background-image: url("hand-in-yellow.b244739f.png");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 1135px;
  height: 270px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 1160px) {
  .banner-get-gas:after {
    width: 100%;
    height: 220px;
  }
}

@media (max-width: 1000px) {
  .banner-get-gas:after {
    background-image: url("hand-in-yellow-mobi.5de1d2cd.svg");
    background-position: 100% calc(50% + 2px);
  }
}

@media (max-width: 720px) {
  .banner-get-gas:after {
    background-position: 100% calc(50% - 4px);
    height: 200px;
  }
}

.banner-get-gas h3 {
  color: #2c3034;
  font-feature-settings: "liga" off;
  max-width: 450px;
  margin: 0 0 24px;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
}

@media (max-width: 620px) {
  .banner-get-gas h3 {
    width: 170px;
    margin: 0 0 18px;
    font-size: 21px;
  }
}

.banner-get-gas__download {
  z-index: 2;
  color: #f3ebea;
  text-align: center;
  font-feature-settings: "calt" off, "liga" off;
  cursor: pointer;
  background-color: #2c3034;
  border-radius: 16px;
  padding: 10px 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.banner-get-gas__download:hover {
  background-color: #35393b;
}

.news {
  background-image: url("pattern.14277ddb.svg");
  background-size: cover;
  max-width: 100%;
  margin: 0;
  padding: 164px 156px 0;
}

@media (max-width: 1160px) {
  .news {
    padding: 80px 20px 0;
  }
}

.news-list {
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: flex;
}

@media (max-width: 620px) {
  .news-list {
    flex-direction: column;
  }
}

.news-item {
  cursor: pointer;
  background: #2c3034;
  border-radius: 16px;
  flex-direction: column;
  width: 362px;
  height: 420px;
  transition: transform .2s;
  display: flex;
}

@media (max-width: 620px) {
  .news-item {
    width: 100%;
  }
}

.news-item:hover {
  transform: scale(1.02);
}

.news-item:hover a {
  background: #ffffff1a;
}

.news-item:hover a img {
  transform: translateX(4px);
}

@media (max-width: 1160px) {
  .news-item:last-child {
    display: none;
  }
}

.news-item h3 {
  color: #f3ebea;
  margin: 0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.news-item p {
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  white-space: break-spaces;
  color: #f3ebea;
  -webkit-box-orient: vertical;
  width: 100%;
  margin-top: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-box;
  overflow: hidden;
}

.news-item-content {
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 20px;
  display: flex;
}

.news-item-content p {
  font-weight: 400 !important;
}

.news-item > img {
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  max-width: 362px;
  height: 191px;
}

.news-item a {
  color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  margin-left: -8px;
  padding: 4px 18px 4px 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;
  transition: background .2s;
  display: flex;
}

.news-item a:hover {
  background: #ffffff1a;
}

.news-item a:hover img {
  transform: translateX(4px);
}

.news-item a img {
  margin-left: 8px;
  transition: transform .2s;
}

.download-banner {
  background: #ebdedc;
  border-radius: 16px;
  justify-content: space-between;
  align-items: flex-end;
  height: 343px;
  display: flex;
}

@media (max-width: 1160px) {
  .download-banner {
    overflow: hidden;
  }
}

@media (max-width: 800px) {
  .download-banner {
    text-align: center;
    background: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.download-banner > img {
  object-fit: contain;
  width: 327px;
}

@media (max-width: 1160px) and (min-width: 800px) {
  .download-banner > img {
    margin-right: -42px;
  }
}

@media (max-width: 800px) {
  .download-banner > img {
    margin-top: 64px;
  }
}

.download-banner-links {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.download-banner-links a {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Open Sans, sans-serif;
  font-weight: 600;
}

.download-banner-text {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  padding: 24px;
  display: flex;
}

@media (max-width: 800px) {
  .download-banner-text {
    padding: 0;
  }
}

.download-banner-text p {
  color: #6b6661;
  margin-top: 0;
  margin-bottom: 50px;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

.download-banner-text p span {
  color: #2c3034;
}

.download-banner-qr {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 334px;
  height: 100%;
  padding-top: 29px;
  display: flex;
}

@media (max-width: 1160px) {
  .download-banner-qr {
    display: none;
  }
}

.download-banner-qr p {
  color: #2c3034;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.binance-features {
  gap: 16px;
  margin: 0 0 32px;
  display: flex;
  position: relative;
}

@media (max-width: 1000px) {
  .binance-features {
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 0 16px;
  }
}

.binance-features__wrapper {
  margin-top: 0;
  margin-bottom: 242px;
  position: relative;
}

@media (max-width: 1000px) {
  .binance-features__wrapper {
    margin: 0 auto 74px;
    padding: 0;
  }
}

.binance-features__wrapper h2 {
  margin: 0 auto 16px;
}

.binance-features__wrapper p {
  text-align: center;
  margin: 0 auto 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

.binance-features__wrapper .background {
  background-image: url("pattern.14277ddb.svg");
  background-repeat: no-repeat;
  width: 100vw;
  height: 692px;
  position: absolute;
  top: 10%;
  left: -15.625vw;
}

.weekly-score__top {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 1066px;
  margin: 0 auto 72px;
  display: flex;
}

@media (max-width: 1130px) {
  .weekly-score__top {
    box-sizing: border-box;
    width: calc(100% - 56px);
  }
}

@media (max-width: 800px) {
  .weekly-score__top {
    flex-wrap: wrap;
    justify-content: center;
    width: 375px;
    max-width: 100%;
    margin: 0 auto 16px;
  }
}

@media (max-width: 340px) {
  .weekly-score__top {
    margin: 0 auto 8px;
  }
}

.weekly-score__bottom {
  width: 726px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 800px) {
  .weekly-score__bottom {
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: 375px;
    max-width: 100%;
    display: flex;
  }
}

.weekly-score__bottom_1 {
  width: 248px;
  margin: 0 0 8px;
}

@media (max-width: 1130px) {
  .weekly-score__bottom_1 {
    width: 24%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_1 {
    order: 1;
    width: 162px;
    max-width: calc(50% - 4px);
    margin: 0;
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_1 {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.weekly-score__bottom_2 {
  width: 248px;
}

@media (max-width: 1130px) {
  .weekly-score__bottom_2 {
    width: 24%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_2 {
    order: 3;
    width: 168px;
    max-width: calc(50% - 4px);
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_2 {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.weekly-score__bottom_3 {
  width: 262px;
  margin: 0 0 24px;
}

@media (max-width: 1130px) {
  .weekly-score__bottom_3 {
    width: 26%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_3 {
    order: 4;
    width: 162px;
    max-width: calc(50% - 4px);
    margin: 0;
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_3 {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.weekly-score__bottom_4 {
  width: 257px;
}

@media (max-width: 1130px) {
  .weekly-score__bottom_4 {
    width: 26%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_4 {
    order: 2;
    width: 172px;
    max-width: calc(50% - 4px);
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_4 {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.weekly-score__bottom_5 {
  width: 388px;
}

@media (max-width: 1060px) {
  .weekly-score__bottom_5 {
    width: 48%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_5 {
    width: 182px;
    max-width: calc(50% - 4px);
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_5 {
    width: calc(100% - 32px);
    max-width: 100%;
  }
}

.weekly-score__bottom_6 {
  width: 350px;
  position: absolute;
  top: 32px;
  right: 0;
}

@media (max-width: 1060px) {
  .weekly-score__bottom_6 {
    width: 48%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_6 {
    width: 174px;
    max-width: calc(50% - 4px);
    margin: 8px 0 0;
    position: relative;
    top: auto;
    right: auto;
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_6 {
    width: calc(100% - 32px);
    max-width: 100%;
    margin: 0;
  }
}

.weekly-score__bottom_7 {
  width: 397px;
  position: absolute;
  top: 254px;
  left: 196px;
}

@media (max-width: 1060px) {
  .weekly-score__bottom_7 {
    width: 55%;
  }
}

@media (max-width: 800px) {
  .weekly-score__bottom_7 {
    width: 186px;
    max-width: calc(50% - 4px);
    top: 119px;
    left: 109px;
  }
}

@media (max-width: 340px) {
  .weekly-score__bottom_7 {
    width: calc(100% - 32px);
    max-width: 100%;
    position: relative;
    top: auto;
    left: auto;
  }
}

.reviews {
  width: 1136px;
  margin: 0 auto;
}

.reviews-wrapper {
  gap: 12px;
  display: flex;
}

.review {
  background-color: #ebdedc;
  border-radius: 16px;
  flex-direction: column;
  flex-shrink: 0;
  width: 460px;
  padding: 24px;
  display: flex;
}

.review-header {
  align-items: center;
  gap: 12px;
  margin: 0 0 16px;
  display: flex;
}

.review-header__avatar {
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

.review-header__name h3 {
  color: #2c3034;
  font-feature-settings: "liga" off;
  font-size: 24px;
  font-weight: 800;
}

.review-header__name h5 {
  color: #6b6661;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.review-header .soc-icon {
  width: 24px;
  margin: 0 0 0 auto;
}

.review-content p {
  color: #2c3034;
  padding: 0 8px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.review-date {
  margin: auto 0 0;
  padding: 0 8px;
}

.ask-block {
  cursor: pointer;
  background: #ebdedc;
  border-radius: 16px;
  width: 100%;
  transition: height .2s, background .2s;
  overflow: hidden;
}

.ask-block + .ask-block {
  margin-top: 12px;
}

.ask-block:hover {
  background: #e6d7d5;
}

.ask-block.open img {
  transform: rotateZ(-180deg);
}

.ask-block-header {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 32px 24px;
  display: flex;
}

.ask-block-header p {
  color: #2c3034;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.ask-block-header img {
  transition: transform .35s;
}

.ask-block-body p {
  color: #6b6661;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.ask-block-body {
  padding: 0 24px 32px;
  display: none;
}

.ask-block ul {
  color: #6b6661;
  margin: 0;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.secure-near {
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin: 0 auto 140px;
  display: flex;
}

@media (max-width: 1000px) {
  .secure-near {
    margin: 0 auto 74px;
  }
}

.secure-near__item {
  background-color: #ebdedc;
  border-radius: 32px;
  flex-direction: column;
  width: calc(50% - 8px);
  padding: 0 0 48px;
  display: flex;
  position: relative;
}

@media (max-width: 750px) {
  .secure-near__item {
    width: 100%;
  }
}

.secure-near__item img {
  width: 100%;
  margin: 0;
  display: block;
}

@media (max-width: 720px) {
  .secure-near__item img {
    width: calc(100% - 32px);
    margin: 0 auto;
  }

  .secure-near__item-independently img {
    width: 300px;
    margin: 32px auto 40px;
  }
}

@media (max-width: 400px) {
  .secure-near__item-independently img {
    width: calc(100% - 32px);
  }
}

.secure-near__keys {
  gap: 12px;
  padding: 81px 48px 0;
  display: flex;
}

@media (max-width: 800px) {
  .secure-near__keys {
    padding: 32px 16px 0;
  }
}

.secure-near__keys_1 {
  margin: 0 0 16px;
}

@media (max-width: 400px) {
  .secure-near__keys_1 {
    margin: 0 0 24px;
  }
}

.secure-near__keys_2 {
  margin: 0 0 77px;
  padding: 0 0 0 64px;
}

@media (max-width: 1060px) {
  .secure-near__keys_2 {
    margin: 0 0 81px;
  }
}

@media (max-width: 400px) {
  .secure-near__keys_2 {
    margin: 0 0 40px;
    padding: 0 16px;
  }
}

.secure-near__keys-item {
  color: #2c3034;
  font-feature-settings: "liga" off;
  background-color: #d9cdcb;
  border: 1px solid #2c3034;
  border-radius: 48px;
  padding: 24px 32px;
  font-size: 32px;
  font-weight: 900;
}

@media (max-width: 1060px) {
  .secure-near__keys-item {
    padding: 16px 24px;
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  .secure-near__keys-item {
    text-align: center;
    width: calc(50% - 6px);
    padding: 16px;
    font-size: 18px;
  }
}

.item-content {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.item-content h3 {
  color: #2c3034;
  font-feature-settings: "liga" off;
  margin: 0 0 12px;
  padding: 0 36px 0 48px;
  font-size: 40px;
  font-weight: 900;
}

@media (max-width: 800px) {
  .item-content h3 {
    padding: 0 32px;
    font-size: 32px;
  }
}

.item-content p {
  color: #2c3034;
  margin: 0;
  padding: 0 48px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

@media (max-width: 800px) {
  .item-content p {
    padding: 0 32px;
    font-size: 18px;
  }
}

.item-content__hardware {
  bottom: 60px;
}

@media (max-width: 800px) {
  .item-content__hardware {
    bottom: 40px;
  }
}

.item-content__independently {
  bottom: 88px;
}

@media (max-width: 1060px) {
  .item-content__independently {
    bottom: 60px;
  }
}

@media (max-width: 800px) {
  .item-content__independently {
    bottom: 40px;
  }
}

.item-content__safety {
  bottom: 60px;
}

@media (max-width: 800px) {
  .item-content__safety {
    bottom: 40px;
  }
}

.item-content__keys {
  bottom: 92px;
}

@media (max-width: 1060px) {
  .item-content__keys {
    bottom: 82px;
  }
}

@media (max-width: 800px) {
  .item-content__keys {
    bottom: 54px;
  }
}

@media (max-width: 720px) {
  .item-content {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
}

.partners {
  flex-direction: column;
  gap: 12px;
  padding-bottom: 140px;
  display: flex;
}

@media (max-width: 500px) {
  .partners {
    padding-bottom: 80px;
  }
}

.partners-group {
  box-sizing: border-box;
  background: #ebdedc;
  border-radius: 16px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px 62px;
  padding: 80px 80px 50px;
  display: flex;
  position: relative;
}

@media (max-width: 1160px) {
  .partners-group {
    justify-content: center;
    gap: 40px;
  }
}

.partners-group a {
  display: inline-block;
}

.partners-wrap {
  gap: 12px;
  display: flex;
}

@media (max-width: 1160px) {
  .partners-wrap {
    flex-direction: column;
  }
}

.partners-wrap div {
  flex: 1;
}

.partners-group p {
  color: #2c3034;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  position: absolute;
  top: 0;
}

.partners-group a {
  transition: transform .2s;
}

.partners-group a:hover {
  transform: scale(1.05);
}

.partners-group img {
  object-fit: contain;
  object-position: center;
  display: block;
}

@media (max-width: 1160px) {
  .partners-group {
    padding: 72px 72px 22px;
  }
}

@media (max-width: 500px) {
  .partners-group {
    gap: 24px;
    padding: 32px 32px 24px;
  }

  .partners-group a {
    margin: 0 0 12px;
  }

  .partners-group a img {
    width: 100% !important;
  }
}

.footer {
  padding: 112px 0 82px;
}

.footer__header {
  justify-content: space-between;
  align-items: center;
  width: 1120px;
  max-width: calc(100% - 48px);
  margin: 0 auto 40px;
  padding: 0 24px;
  display: flex;
}

@media (max-width: 1000px) {
  .footer__header {
    flex-direction: column;
    gap: 24px;
  }
}

.footer-logo {
  height: 64px;
}

@media (max-width: 400px) {
  .footer-logo {
    height: 40px;
  }
}

.footer-socials {
  gap: 16px;
  display: flex;
}

.footer-mail {
  text-align: right;
  color: #f3ebea;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

.footer-terms {
  text-align: center;
  color: #f3ebea;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

.footer__down {
  justify-content: space-between;
  align-items: center;
  gap: 5vw;
  width: 1120px;
  max-width: calc(100% - 48px);
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
}

@media (max-width: 1000px) {
  .footer__down {
    flex-direction: column;
    gap: 24px;
  }
}

.footer-link {
  color: #f3ebea;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  text-decoration: none;
}

.footer-download {
  color: #f3ebea;
  background-image: url("android-logo.da771194.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 24px;
  padding: 0 0 0 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  text-decoration: none;
  display: inline-block;
}

.footer__menu {
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 1000px) {
  .footer__menu {
    gap: 24px;
  }
}

@media (max-width: 400px) {
  .footer__menu {
    text-align: center;
    flex-direction: column;
  }
}

.footer a {
  transition: opacity .2s;
}

.footer a:hover {
  opacity: .8;
}

@keyframes anvil {
  0% {
    opacity: 0;
    transform: scale(1)translateY(0);
    box-shadow: 0 0 #f1f1f100;
  }

  1% {
    opacity: 0;
    transform: scale(.96)translateY(10px);
    box-shadow: 0 0 #f1f1f100;
  }

  100% {
    opacity: 1;
    transform: scale(1)translateY(0);
    box-shadow: 0 0 500px #f1f1f100;
  }
}

@keyframes show {
  0% {
    background: none;
  }

  100% {
    background: #0009;
  }
}

.modal-wrap {
  z-index: 30005;
  justify-content: center;
  align-items: center;
  padding: 16px;
  animation: .3s cubic-bezier(.38, .1, .36, .9) forwards show;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-wrap.open {
  display: flex;
}

.modal-close {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  transition: opacity .2s;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}

.modal-close:hover {
  opacity: .5;
}

.modal-body {
  background: #f3ebea;
  border-radius: 16px;
  width: 480px;
  padding: 32px;
  animation: .3s cubic-bezier(.38, .1, .36, .9) forwards anvil;
  position: relative;
}

.modal-body h2 {
  text-align: center;
  font-feature-settings: "liga" off;
  color: #2c3034;
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
}

.modal-body p {
  text-align: center;
  color: #6b6661;
  margin: 0 0 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.form-block {
  position: relative;
}

.form-block input {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 12px;
}

.form-block .error-valid {
  color: #d63e3e;
  margin: 0;
  padding: 0 16px;
  font-size: 14px;
  display: none;
}

.form-block .here-button {
  cursor: pointer;
  width: 100%;
  margin: 16px 0 0;
}

#success-modal .modal-body {
  justify-content: center;
  height: 314px;
}

#success-modal p {
  text-align: center;
  color: #2c3034;
  margin-top: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

/*# sourceMappingURL=index.128ff6e2.css.map */
