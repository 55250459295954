@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')
body
	padding: 0
	margin: 0
	background-color: #2c3034
	font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif
	overflow-x: hidden
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	-webkit-text-size-adjust: 100%
	-ms-text-size-adjust: 100%
	text-size-adjust: 100%
	text-rendering: optimizeLegibility

h1,
h2,
h3,
h4,
h5,
h6
	margin: 0
	font-weight: 800
	font-family: CabinetGrotesk, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif

span.dot
	font-family: CabinetGrotesk
	color: rgba(253, 132, 227, 1)
	font-weight: 800

section
	max-width: 1120px
	margin: 164px auto 0

	@media (max-width: 1160px)
		margin-top: 80px
		padding: 0 24px
	@media (max-width: 1000px)
		padding: 0 16px

	&>h2
		display: block
		margin: auto
		margin-bottom: 56px
		font-weight: 900
		font-size: 56px
		line-height: 69px
		text-align: center
		letter-spacing: 0.02em
		color: #2C3034
		width: 80%

		@media (max-width: 768px)
			font-size: 40px
			line-height: 50px
			text-align: center
			margin-bottom: 40px
			width: 100%

		@media (max-width: 580px)
			font-size: 32px
			line-height: 40px
			width: 100%

.here-button.light
	box-shadow: 4px 4px 0px #fff

.here-button
	cursor: pointer
	font-family: 'Inter'
	box-sizing: border-box
	display: flex
	flex-direction: row
	justify-content: center
	align-items: center
	padding: 12px 16px
	gap: 8px

	background: #f7b302
	width: 180px
	height: 48px

	border: 1px solid #2c3034
	box-shadow: 4px 4px 0px #2c3034
	border-radius: 16px

	font-weight: 800
	font-size: 16px
	line-height: 22px
	text-align: center
	color: #2c3034
	text-decoration: none
	transition: 0.2s background

	&:hover
		background: #ffcc4a

	&:disabled
		cursor: default
		background: #CBC6C5
		box-shadow: 4px 4px 0px #6B6661
		border: 1px solid #6B6661
		color: #6B6661

.here-button.pink
	background: #fd84e3
	&:hover
		background: #FD9BE8
.here-button.sunyellow
	background-color: #FCE823
	&:hover
		background-color: #FDBF1E
.here-input, .here-input-nick
	display: flex
	font-family: 'Inter'
	flex-direction: column
	justify-content: center
	align-items: flex-start
	font-style: normal
	font-weight: 500
	font-size: 16px
	line-height: 22px
	color: #2C3034
	border: none
	padding: 0
	outline: none
	padding: 0 16px

	background: #EBDEDC
	border: 1px solid #2C3034
	border-radius: 16px
	height: 56px

	&::placeholder
		color: #6B6661
.page
	position: relative

	&-background
		height: 100%
		background: #f3ebea
		border-radius: 32px
		display: block
		position: absolute
		z-index: -1
		left: 88px
		right: 88px

.full-hide
	display: none
