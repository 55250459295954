.partners
    padding-bottom: 140px
    display: flex
    flex-direction: column
    gap: 12px
    @media (max-width: 500px)
        padding-bottom: 80px
    &-group
        background: #EBDEDC
        border-radius: 16px
        padding: 80px 80px 50px 80px
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
        position: relative
        box-sizing: border-box
        gap: 40px 62px
        @media (max-width: 1160px)
            justify-content: center
            gap: 40px
        a
            display: inline-block
    &-wrap
        display: flex
        gap: 12px
        @media (max-width: 1160px)
            flex-direction: column

        div
            flex: 1

    &-group p
        font-weight: 700
        font-size: 28px
        line-height: 38px
        color: #2C3034
        position: absolute
        top: 0

    &-group a
        transition: 0.2s transform
        &:hover
            transform: scale(1.05)

    &-group img
        object-fit: contain
        object-position: center
        display: block
    
    @media (max-width: 1160px)
        &-group
            padding: 72px 72px 22px 72px

    @media (max-width: 500px)
        &-group
            gap: 24px
            padding: 32px 32px 24px 32px
            a
                margin: 0 0 12px 0
                img
                    width: 100% !important