.reviews
    width: 1136px
    margin: 0 auto
    &-wrapper
        display: flex
        gap: 12px
.review
    display: flex
    flex-direction: column
    flex-shrink: 0
    width: 460px
    padding: 24px
    border-radius: 16px
    background-color: #EBDEDC
    &-header
        display: flex
        align-items: center
        gap: 12px
        margin: 0 0 16px 0
        &__avatar
            width: 64px
            height: 64px
            border-radius: 50%
        &__name
            h3
                color: #2C3034
                font-feature-settings: 'liga' off
                font-size: 24px
                font-weight: 800
            h5
                color: #6B6661
                font-family: Inter
                font-size: 18px
                font-weight: 500
                line-height: 1.3
        .soc-icon
            width: 24px
            margin: 0 0 0 auto
    &-content
        p
            padding: 0 8px
            color: #2C3034
            font-family: Inter
            font-size: 18px
            font-weight: 500
            line-height: 1.3
    &-date
        margin: auto 0 0 0
        padding: 0 8px