@keyframes anvil
    0%
        transform: scale(1) translateY(0px)
        box-shadow: 0 0 0 rgba(241, 241, 241, 0)
        opacity: 0

    1%
        transform: scale(0.96) translateY(10px)
        box-shadow: 0 0 0 rgba(241, 241, 241, 0)
        opacity: 0

    100%
        transform: scale(1) translateY(0px)
        box-shadow: 0 0 500px rgba(241, 241, 241, 0)
        opacity: 1

@keyframes show
    0%
        background: rgba(0, 0, 0, 0)

    100%
        background: rgba(0, 0, 0, 0.6)

.modal-wrap
    animation: show 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards
    display: none
    align-items: center
    justify-content: center
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 30005
    padding: 16px

    &.open
        display: flex

.modal-close
    position: absolute
    top: 16px
    right: 16px
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    background: transparent
    border: none
    cursor: pointer
    transition: 0.2s opacity
    &:hover
        opacity: 0.5

.modal-body
    position: relative
    background: #F3EBEA
    border-radius: 16px
    padding: 32px
    width: 480px
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards

    h2
        font-weight: 900
        font-size: 32px
        line-height: 40px
        text-align: center
        font-feature-settings: 'liga' off
        color: #2C3034
        margin: 0 0 8px 0

    p
        margin: 0 0 32px 0
        font-weight: 600
        font-size: 18px
        line-height: 25px
        text-align: center
        color: #6B6661

.form-block
    position: relative
    input
        box-sizing: border-box
        width: 100%
        margin: 0 0 12px 0
    .error-valid
        display: none
        margin: 0
        padding: 0 16px
        font-size: 14px
        color: #D63E3E
    .here-button
        width: 100%
        margin: 16px 0 0 0
        cursor: pointer

#success-modal
    .modal-body
        height: 314px
        justify-content: center

    p
        font-weight: 600
        font-size: 18px
        line-height: 25px
        text-align: center
        color: #2C3034
        margin-top: 32px
