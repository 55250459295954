.footer
    padding: 112px 0 82px
    &__header
        display: flex
        align-items: center
        justify-content: space-between
        width: 1120px
        max-width: calc( 100% - 48px )
        margin: 0 auto 40px auto
        padding: 0 24px
        @media (max-width: 1000px)
            flex-direction: column
            gap: 24px

    &-logo
        height: 64px
        @media (max-width: 400px)
            height: 40px

    &-socials
        display: flex
        gap: 16px

    &-mail
        font-weight: 500
        font-size: 16px
        line-height: 20px
        text-align: right
        color: #F3EBEA
        margin-bottom: 0
        text-decoration: none


    &-terms
        font-weight: 500
        font-size: 16px
        line-height: 20px
        text-align: center
        color: #F3EBEA
        text-decoration: none

    &__down
        display: flex
        align-items: center
        justify-content: space-between
        width: 1120px
        max-width: calc( 100% - 48px )
        margin: 0 auto
        padding: 0 24px
        gap: 5vw
        @media (max-width: 1000px)
            flex-direction: column
            gap: 24px
    &-link
        font-size: 16px
        font-weight: 500
        line-height: 1.25
        color: #F3EBEA
        text-decoration: none
    &-download
        display: inline-block
        padding: 0 0 0 32px
        font-size: 16px
        font-weight: 500
        line-height: 1.25
        color: #F3EBEA
        text-decoration: none
        background-image: url(../assets/icons/android-logo.svg)
        background-position: 0 50%
        background-repeat: no-repeat
        background-size: 24px auto
    &__menu
        display: flex
        justify-content: space-between
        flex-grow: 1
        @media (max-width: 1000px)
            gap: 24px
        @media (max-width: 400px)
            flex-direction: column
            text-align: center
    & a
        transition: 0.2s opacity
        &:hover
            opacity: 0.8